import React, { Fragment } from 'react';
import ProductAddToCart from "../productPage/ProductAddToCart";
import { NavLink } from "react-router-dom";
import { globalFileIcon } from '../../../../axiosInstance'
import ReactTooltip from "react-tooltip";
import { useSelector, useDispatch } from 'react-redux';
import { setUserEntry } from '../../../server/Customer'
import Detailes from './cardDeailes';

const Card = ({ element, productLocationPopUp, productPopUp, addToShopListPopUp, comparePricesPopUp }) => {
    const WeightJump = element.WeightJump > 0 ? element.WeightJump : 1;
    const dispatch = useDispatch();
    const role = useSelector(state => state.user.role)
    const user = useSelector(state => state.user.user)
    const inCart = useSelector(state => state.productsInCart.productsInCart.find(item => item.ProductMkt === element.Mkt || item.ProductMkt === element.ProductMkt));

    const AddToShopListIfUser = () => {
        if (!user) {
            dispatch(setUserEntry([true, "signIn"]))
        }
        else {
            addToShopListPopUp()
        }
    }

    return (
        <Fragment >
            <div className={role ? "item-card role" : "item-card"}>
                {role ?
                    (element.QuantityCanOrder >= WeightJump ?
                        <div className='location' onClick={() => productLocationPopUp(element)}>
                            <button className={`inventory ${element.ViewOutOfStockId === 2 ? 'red' : 'gray'}`}
                                data-tip data-for="registerTipLocation"> {element.QuantityCanOrder}

                            </button>
                        </div> :
                        element.ViewOutOfStockId === 2 ?
                            <div className='location' onClick={productLocationPopUp}>
                                <button className="inventory red" data-tip data-for="registerTipLocation">X
                                </button>
                            </div> :
                            <div className='location' onClick={() => productLocationPopUp(element)}>
                                <button className="inventoryEnd-btn" data-tip data-for="registerTipLocation">חסר
                                </button>
                            </div>) :
                    (element.QuantityCanOrder < WeightJump) ?
                        <p className="inventoryEnd">חסר</p> : null
                }
                <ReactTooltip id="registerTipLocation" place="top" effect="solid">לחץ כדי לראות מיקום.
                </ReactTooltip>
                <Detailes element={element} inCart={inCart} productPopUp={productPopUp} />


                <div className='details'>
                    {role ? <div className='compare-prices'>
                        <div className='compare-btn' onClick={comparePricesPopUp} data-tip data-for="registerTipComparePrice">
                            <p> ₪ </p>
                        </div>
                    </div> : null}
                    <ReactTooltip id="registerTipComparePrice" place="top" effect="solid">
                        לחץ כדי להשוות מחיר.
                    </ReactTooltip>
                    {role ?
                        <div className="edit-prod-cls">
                            <NavLink to={"/edit-product/" + element.Mkt} data-tip data-for="registerTipDefinition">
                                <img loading="lazy" src={globalFileIcon + 'gear.svg'} alt="gear" />
                            </NavLink>
                        </div>
                        : null}
                    {element.DisableProductId > 1 ?
                        <p className="inventoryEnd  disable-product">{element.DisableProduct}</p>
                        : null}
                    <ReactTooltip id="registerTipDefinition" place="top" effect="solid">
                        לחץ כדי לעדכן פרטי מוצר.
                    </ReactTooltip>
                </div>
                <div className='add-btn'>
                    <ProductAddToCart element={element} haveInfo={true} />
                    {!role ? <img loading="lazy" className='add-to-list' src={globalFileIcon + 'listInMemu.svg'}
                        alt="list" onClick={AddToShopListIfUser} data-tip data-for="registerTipAddToList" /> : null}
                </div>
                <ReactTooltip id="registerTipAddToList" place="top" effect="solid">הוסף לרשימת קניות</ReactTooltip>

            </div>

        </Fragment>
    )
}

export default Card
