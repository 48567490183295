import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { getItemsPerCategory, UpdateProduct } from '../../../server/Product';
import { CategoryUpdate, CategoryAdd } from '../../../server/Category';
import { FiEdit2, FiCheckSquare, FiSquare, FiSave, FiPlus, FiTrash } from 'react-icons/fi';
import './showCategory.scss';
import AddCategoryDialog from './addCategoryDialog';
import { PlusCircle } from 'lucide-react';
import DeleteCategoryDialog from './deleteCategoryDialog'


const ShowCategory = ({ fatherId = 0, isNested = false, level = 0 }) => {
    const { categoriesState, stationId } = useSelector(state => ({
		categoriesState: state.category.categories,
        stationId: state.user.stationId,
	}));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentItems, setCurrentItems] = useState(categoriesState.filter(item => item.FatherId === fatherId));
    const [addingDialogOpen, setAddingDialogOpen] = useState(false);
    const [fatherIdAddCategory, setFatherIdAddCategory] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState(null);


    useEffect(() => {
        setCurrentItems(categoriesState.filter(item => item.FatherId === fatherId));
    }, [categoriesState]);
    
    
    const addNewCategory = (newCategoryName) =>{
        if (newCategoryName.trim() !== ""){
            const data = {
                FatherId:  fatherIdAddCategory,
                Name: newCategoryName
            }
            dispatch(CategoryAdd(data));
        }
        setAddingDialogOpen(false);
    }

    const updateCategory = (id, title, value) => {
        dispatch(CategoryUpdate({
            Mkt: id,
            Title: title,
            value: value
        }));
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;

        const reorderedItems = [...currentItems];
        const [removed] = reorderedItems.splice(source.index, 1);
        reorderedItems.splice(destination.index, 0, removed);
        reorderedItems.forEach((item, index) => {
            item.OrderNumber = index + 1;
            updateCategory(item.Id, "OrderNumber", item.OrderNumber);
        });
        setCurrentItems(reorderedItems);
        let items = categoriesState;
        items.sort((a, b) => { return a.OrderNumber - b.OrderNumber });
    };

    const [expandedCards, setExpandedCards] = useState({});
    const [renamingCardId, setRenamingCardId] = useState(null);
    const [renameInputValue, setRenameInputValue] = useState("");

    const toggleExpandCard = (cardId) => {
        setExpandedCards(prev => ({
            ...prev,
            [cardId]: !prev[cardId]
        }));
    };

    const handleCardClick = (id, children) => {
        if (children === 0)
            navigate(`/products-edit/${id}`);
        else
            toggleExpandCard(id);
    };

    const toggleActiveStatus = (card) => {
        const updatedCard = { ...card, Active: !card.Active };
        updateCategory(updatedCard.Id, "Active", updatedCard.Active);
        setCurrentItems(currentItems.map(item => item.Id === card.Id ? updatedCard : item));
    };

    const startRenamingCard = (card) => {
        setRenamingCardId(card.Id);
        setRenameInputValue(card.Name);
    };

    const saveRenamingCard = (card) => {
        updateCategory(card.Id, "Name", renameInputValue);
        setCurrentItems(currentItems.map(item => item.Id === card.Id ? { ...card, Name: renameInputValue } : item));
        setRenamingCardId(null);
    };

    const getBackgroundColor = () => {
        switch (level) {
            case 1: return '#f0f9ff';
            case 2: return '#e0f7fa';
            default: return '#fff';
        }
    };

    const openAddingDialog = (id) => {
        setFatherIdAddCategory(id);
        setAddingDialogOpen(true);
    }

    const closeDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };
    
    const handleDelete = (id) => {
        getItemsPerCategory(id, stationId)
        .then(x => {
            const items = x.data;
            if(items.length === 0){
                deleteEmptyCategory(id);
            }
            else {
                setCategoryToDelete(id);
                setDeleteDialogOpen(true);
            }
        }).catch((e)=>console.log("error", e)) 
    }

    const deleteEmptyCategory = (id) => {
        /*dispatch(CategoryDelete(id))
            .then(() => {
                setCurrentItems(currentItems.filter(item => item.Id !== id));
            })
            .catch((error) => {
                console.log(error);
        });*/
    }

    const deleteCtegoryWithProducts = () => {
        setDeleteDialogOpen(false);
        getItemsPerCategory(categoryToDelete, stationId).
        then(x => {
            const products = x.data;
            const sortCategory = categoriesState.filter(x => x.Name === "מיון")[0];
            if(sortCategory === undefined){
                setFatherIdAddCategory(0);
                addNewCategory("מיון");
                sortCategory = categoriesState.filter(x => x.Name === "מיון")[0];
            }
            products.forEach(prod => {
                prod.CategoryId = sortCategory.Id;
                /*UpdateProduct({
                    Mkt: prod.Mkt,
                    Title: "CategoryId",
                    value: sortCategory.Id
                });*/
            })
            deleteEmptyCategory(categoryToDelete);
        })
    };
      
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={isNested ? `nested-list-${level}` : 'list'}>
                {(provided) => (
                    <div
                        className={`categories-container ${isNested ? 'nested-categories' : ''}`}
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        {level === 0 ? <div className="category-item add-category-item" onClick={()=>openAddingDialog(0)}>
                            <div className="add-category-content" >
                                <div className="circle-icon">
                                <PlusCircle className="plus-icon" />
                                </div>
                                <p>הוסף מחלקה חדשה</p>
                            </div>
                        </div> : null}
                        {currentItems.map((item, index) => {
                        const children = categoriesState.filter(x => x.FatherId === item.Id);
                        return (
                            <Draggable 
                                key={item.Id.toString()} 
                                draggableId={item.Id.toString()} 
                                index={index} 
                                isDragDisabled={children.length > 0 && expandedCards[item.Id]}>
                                {(provided) => (
                                    <div 
                                        className="category-item"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}>
                                        <div className={`category-content level-${level}`} style={{backgroundColor: getBackgroundColor()}}>
                                            {renamingCardId === item.Id ? (
                                            <form className="rename-form" onSubmit={(e) => { e.preventDefault(); saveRenamingCard(item); }}>
                                                <input
                                                    type="text"
                                                    value={renameInputValue}
                                                    onChange={(e) => setRenameInputValue(e.target.value)}
                                                    className="rename-input"
                                                    autoFocus
                                                    aria-label="Rename category"
                                                />
                                                <button 
                                                    type="submit"
                                                    className="rename-button"
                                                    aria-label="Save new name">
                                                    <FiSave size={20} />
                                                </button>
                                            </form>
                                            ) : (
                                                <span className="category-name" onClick={() => handleCardClick(item.Id, children.length)}>{item.Name}</span>
                                            )}
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                {children.length === 0 && <button 
                                                    onClick={() => handleDelete(item.Id)} 
                                                    className="delete-button">
                                                    <FiTrash size={20} />
                                                </button>}
                                                <button 
                                                    onClick={() => startRenamingCard(item)} 
                                                    className="rename-button">
                                                    <FiEdit2 size={20} />
                                                </button>
                                                <button
                                                    onClick={() => toggleActiveStatus(item)} 
                                                    className="toggle-active-button">
                                                    {item.Active ? <FiCheckSquare size={20} /> : <FiSquare size={20} />}
                                                </button>
                                                <button 
                                                    onClick={() => openAddingDialog(item.Id)} 
                                                    className="add-category-button">
                                                    <FiPlus size={20} />
                                                </button>
                                            </span>
                                        </div>
                                        {expandedCards[item.Id] && children && (
                                            <ShowCategory
                                                fatherId={item.Id}
                                                isNested={true}
                                                level={level + 1} 
                                                className="nested-categories"/>)}
                                    </div>
                                )}
                            </Draggable>
                        );
                    })}
                    {provided.placeholder}
                    </div>)}
            </Droppable>
            {addingDialogOpen && <AddCategoryDialog handleAdding={addNewCategory} handleClose={setAddingDialogOpen} categoryLevel={fatherIdAddCategory === 0 ? 0 : level+1}/>}
            {deleteDialogOpen && (<DeleteCategoryDialog handleDelete={deleteCtegoryWithProducts} handleClose={closeDeleteDialog}/>)}
        </DragDropContext>
    );
};

export default ShowCategory;
