import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { globalFileIcon } from '../../../axiosInstance';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

const NAVIGATOR = () => {
    const { categories, toggleMenu } = useSelector(state => ({
        categories: state.category.categories,
        toggleMenu: state.openCart.toggleMenu,
    }));
    const { pathname } = useLocation();

    // const dispatch = useDispatch();

    return (
        <div className="navigator">
            <nav className={toggleMenu ? 'active main' : 'main'}>


                <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} RTL>
                    <div className="item-sale">

                        <img loading="lazy" src={globalFileIcon + 'sale-icon.svg'} alt="sale" />

                        <NavLink className={pathname === "/sales" ? "active" : null} to="/sales">מבצעים</NavLink>
                    </div>

                    {categories && categories.length > 0 && categories.map((element, index) =>
                        !element.FatherId && element.Active ?
                            <div style={{ width: '150px' }} key={index} className={pathname.split("/")[2] === element.Id ? "item active" : "item"}>
                                <NavLink to={"/category/" + element.Id}>
                                    {element.Name}
                                </NavLink>
                            </div>
                            : null)
                    }
                </ScrollMenu>



            </nav>
        </div>)
}
const RightArrow = () => {
    const { isFirstItemVisible, scrollPrev } =
        useContext(VisibilityContext);

    return (
        <button disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            <div className="right-btn"><img loading="lazy" src={globalFileIcon + 'open-cart.svg'} alt="open-cart" /></div>
        </button>
    );
}

const LeftArrow = () => {
    const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);

    return (
        <button disabled={isLastItemVisible} onClick={() => scrollNext()}>
            <div className="left-btn"><img loading="lazy" src={globalFileIcon + 'open-cart.svg'} alt="open-cart" /></div>
        </button>
    );
}

export default NAVIGATOR;