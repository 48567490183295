import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { globalFileServer } from "../../../../axiosInstance";
import { toggleFilter } from "../../../server/Product";

const ProductFilterInside = ({ element }) => {
  const dispatch = useDispatch();
  const { itemsState, itemsShow, selectedFilters, typeId } = useSelector(
    (state) => ({
      itemsState: state.displayProduct.items,
      itemsShow: state.displayProduct.itemsShow,
      selectedFilters: state.displayProduct.selectedFilters,
      typeId: state.displayProduct.typeId,
    })
  );

  let items = itemsState;
  return (
    <ul>
      {element.Items.map((el) => {
        let quantity = 0;
        if (
          typeId === element.ParameterName &&
          !selectedFilters.some((x) => x.typeName === typeId && x.id === el.Id)
        ) {
          quantity = items.filter(
            (item) => item[element.ParameterName] === el.Id
          );
        } else {
          quantity = itemsShow.filter(
            (item) => item[element.ParameterName] === el.Id
          );
        }
        return (
          <li
            key={el.Id}
            data-class={!quantity.length ? "disabled" : null}
            className={
              selectedFilters.some(
                (x) => x.id === el.Id && x.typeName === element.ParameterName
              )
                ? "active"
                : null
            }
          >
            <span>{el.Name}</span>
            <span className="quantity">{"(" + quantity.length + ")"}</span>
            <div
              className="input"
              onClick={() =>
                quantity.length
                  ? dispatch(toggleFilter(el.Id, element.ParameterName))
                  : null
              }
            >
              {selectedFilters.some(
                (x) => x.id === el.Id && x.typeName === element.ParameterName
              ) ? (
                <img
                  loading="lazy"
                  src={globalFileServer + "iconcss/done.svg"}
                  alt="done"
                />
              ) : null}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default ProductFilterInside;
