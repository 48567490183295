import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import ProductFilterInside from './ProductFilterInside';



const ProductFilters = memo(({ toggleFilters }) => {
	const filterTypesShow = useSelector(state => state.displayProduct.filterTypesShow);

	return (
		<div className={toggleFilters ? "filters-wrapper active" : "filters-wrapper"}>
			<div className={toggleFilters ? "category-filter hidden" : "category-filter"}>
				<div className="items">
					{/* <div className="filter-title">
						<p>סינון</p>
					</div> */}
					{filterTypesShow.map(element => (
						<div key={element.Id} className="item">
							<div className="wrapper">
								<h2>{element.Name}</h2>
								<div className="wrap">
									<ProductFilterInside element={element} />
								</div>
							</div>
						</div>
					)
					)}
				</div>
			</div>
		</div>
	)
})


export default ProductFilters