import showAlert from '../server/ShowAlert';
import * as actionType from './action'

const initialState = {
    productsInCart: [],
    productSale: [],
    orderSum: 0,// חישוב מחיר מבצע
    totalSum: 0,//לפני חישוב מבצעים
    orderTime: null,
    refresh: false,
    deliveryPrice: 0,//מחיר משלוח
}

const calculateSum = (productsInCart, productSale) => {
    let totalSum = (parseFloat(productsInCart.reduce((prev, x) => prev + +x.Price * x.Quantity, 0))).toFixed(2)
    let orderSum = (parseFloat(productSale.reduce((prev, x) => prev - +x.Price * x.QuantiyUse, 0))).toFixed(2)
    return ({ totalSum, orderSum })
}

const ifHaveToSHowError = (state, payload) => {
    const { Quantity, product, data } = payload;
    let productsInCart = [...state.productsInCart];
    const productSale = [...state.productSale]
    let refresh = false;
    //אין במלאי או מחיר השתנה צריך להוציא הודעת שגיעה
    if (data.IsWarning) {
        //האם יש התראה
        showAlert({ msgTitle: data.CommentWarning, msgTimer: 3000 })
        refresh = true;
    }
    if (Quantity < 0 && product.Quantity && (!product.QuantityCanOrder || product.QuantityCanOrder + Quantity >= 0)) {
        // עדכון שיש לרענן את המידע  
        refresh = true;
    }
    //להוריד מהעגלה את המוצר 
    if (data.Quantity <= 0 && !data.IsWarning) {
        productsInCart = productsInCart.filter(product => product.Id !== data.Id);
    }
    //לשנות מוצר לעגלה
    else {
        const index = productsInCart.findIndex(product => product.ProductMkt === data.ProductMkt);
        if (index >= 0) {
            productsInCart[index] = data;
        }
        //להוסיף לעגלה
        else {
            productsInCart.push(data)
        }

        if (data.CollectSaleProduct) {
            data.CollectSaleProduct.forEach(el => {
                const index = productSale.findIndex(item => (item.CollectSiteProductId == el.CollectSiteProductId))
                if (index >= 0) {
                    productSale[index] = el
                }
                else {
                    productSale.push(el)
                }
            })
        }
    }


    return ({ productsInCart, productSale, refresh })

}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADD_PRODUCT_IN_CART:
            const data = ifHaveToSHowError(state, action.payload)
            const sum = calculateSum(data.productsInCart, data.productSale)
            return {
                ...state,
                ...data,
                ...sum
            }

        case actionType.SET_ALL_PRODUCT:
            const sumSet = calculateSum(action.payload.CollectSiteOrderProductDTO, action.payload.CollectSaleProduct)
            return {
                ...state,
                ...sumSet,
                productSale: action.payload.CollectSaleProduct,
                productsInCart: action.payload.CollectSiteOrderProductDTO,
                orderTime: action.payload.OrderTime
            };
        case actionType.CLEAR_CARD:
            return {
                ...state,
                productsInCart: [],
                productSale: [],
                totalSum: 0,
                orderSum: 0,
                orderTime: null
            };
        case actionType.CANCEL_ERROR:
            return {
                ...state,
            };

        case actionType.SET_ORDER_TIME:
            return {
                ...state,
                orderTime: action.payload
            };
        case actionType.SET_PRODUCTS:
            return {
                ...state,
                refresh: false
            };
        case actionType.SET_DELIVERY_PRICE:
            return {
                ...state,
                deliveryPrice: action.payload
            };



        default:
            return state
    }
}

export default reducer